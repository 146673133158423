import React from "react"

import FLayout from "../components/flayout";
import About from "../components/about";

const Page = ({data}) => (
  <FLayout seoFields={seoFields}>
    <About aboutContent={data.allContentJson.edges[0].node.aboutContent}/>
  </FLayout>
)

const seoFields = {
  description: "With MProfit, we strive to make investing simple, efficient and more profitable. Our solutions are focused on automation and simplicity to help users achieve better investment returns.",
  // lang,
  // meta,
  // keywords,
  title: "About",
}

export const query = graphql`
query aboutUsContentQuery{
  allContentJson {
    edges {
      node {
        aboutContent {
          mission {
            headline
            paragraphs
          }
          story {
            headline
            paragraphs
          }
          team {
            headline
            list {
              order
              name
              designation
              image
            }
          }
          investors {
            headline
            list {
              order
              name
              image
              link
            }
          }
        }
      }
    }
  }
}
`

export default Page
